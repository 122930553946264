
const RongyiYunConfig = {
  // staticResourceDomain: 'http://192.168.1.111:8080',
  // apiDomain: 'http://192.168.1.111:8080',
  // staticResourceDomain: 'http://36.156.144.168:8089',
  // apiDomain: 'http://36.156.144.168:8081',
  staticResourceDomain: process.env.VUE_APP_STATIC_RESOURCE_DOMAIN,
  apiDomain: process.env.VUE_APP_API_DOMAIN,
  rongyiUniversityId:'00000000000000000000000000000000',
  rongyiResourceRoot:'f4c47cbc08cd44eeb10d3a5ef599bd63'
}
module.exports = RongyiYunConfig
