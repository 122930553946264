<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <a href="#" target="_blank">RongYi-Cloud</a>
      <a href="#" target="_blank">Gitee</a>
      <a href="mailto:6688676@qq.com" target="_blank">@hujun</a>
    </template>
    <template v-slot:copyright>
      <a href="http://rongyi.com/" target="_blank">容艺云平台管理系统</a>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
